import { useRouteLoaderData } from '@remix-run/react'
import { z } from 'zod'

export function useProjectData() {
	const data = useRouteLoaderData(
		'routes/_internal+/projects+/$projectId+/_layout',
	)
	const projectSchema = z.object({
		id: z.string(),
		name: z.string(),
	})

	const projectData = projectSchema.safeParse(data)
	if (projectData.success) {
		return projectData.data
	} else {
		return null
	}
}
