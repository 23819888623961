import { cn } from '#app/utils/misc.ts'
import { models } from '#app/utils/models.ts'
import { Link, useNavigate } from '@remix-run/react'
import { useState } from 'react'
import { Button } from './ui/button'

export interface Step {
	model: (typeof models)[keyof typeof models]
}

interface OnboardingProgressProps {
	canProceed?: (currentStep: number) => boolean
	initialStep: number
	projectId: string
	steps: Step[]
}

export default function OnboardingProgress({
	canProceed = () => true,
	initialStep = 0,
	projectId,
	steps,
}: OnboardingProgressProps) {
	const [currentStep, setCurrentStep] = useState(initialStep)
	const navigate = useNavigate()
	const handleBack = () => {
		setCurrentStep((prev) => {
			const index = Math.max(0, prev - 1)
			navigate(steps[index].model.listUrl(projectId))
			return index
		})
	}

	const handleNext = () => {
		setCurrentStep((prev) => {
			const index = Math.min(steps.length - 1, prev + 1)
			navigate(steps[index].model.listUrl(projectId))
			return index
		})
	}

	const currentStepModel = steps[currentStep].model

	return (
		<div className="flex justify-center">
			<div className="w-full mx-4">
				<div className="flex justify-between items-center py-3">
					<Button
						className="text-muted-foreground"
						disabled={currentStep === 0}
						onClick={handleBack}
						variant="link"
					>
						Back
					</Button>
					<Link className="link" to={currentStepModel.listUrl(projectId)}>
						Create {currentStepModel.displayNames.plural}
					</Link>
					<Button
						className="px-8"
						disabled={
							currentStep === steps.length - 1 || !canProceed(currentStep)
						}
						onClick={handleNext}
					>
						Next
					</Button>
				</div>
				<div className="relative">
					<div className="flex justify-center space-x-2 mx-0.5">
						{[...Array(steps.length)].map((_, index) => (
							<div
								className={cn(
									`h-1 mb-1 w-full transition-colors duration-300 rounded-full`,
									index <= currentStep ? 'bg-primary/70' : 'bg-gray-300',
								)}
								key={index}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
