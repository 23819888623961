import { useTheme } from '#app/routes/resources+/theme-switch.tsx'
import { Toaster as Sonner } from 'sonner'
import { Icon } from './icon'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
	const theme = useTheme()

	return (
		<Sonner
			className="toaster group"
			icons={{
				close: <Icon className="text-secondary" name="x" size="xs" />,
			}}
			theme={theme as ToasterProps['theme']}
			toastOptions={{
				classNames: {
					actionButton:
						'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
					cancelButton:
						'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
					description: 'group-[.toast]:text-muted-foreground',
					toast:
						'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
				},
			}}
			{...props}
		/>
	)
}

export { Toaster }
