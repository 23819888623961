import {
	data,
	type HeadersFunction,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useMatches,
	type UIMatch,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { Layout } from './components/layout.tsx'
import { EpicProgress } from './components/progress-bar.tsx'
import { useToast } from './components/toaster.tsx'
import { href as iconsHref } from './components/ui/icon.tsx'
import { Toaster } from './components/ui/sonner.tsx'
import { filterById, filterByTenant } from './models/sqlUtils.server.ts'
import { useTheme } from './routes/resources+/theme-switch.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { Style, stylesToCssVars } from './utils.client/styles-to-css-vars.ts'
import { getUser, logout } from './utils/auth.server.ts'
import { bbai } from './utils/bbai/bbai.ts'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { csrf } from './utils/csrf.server.ts'
import { db } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc'
import { useNonce } from './utils/nonce-provider.ts'
import { getTheme, type Theme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { useOptionalUser } from './utils/user.ts'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ as: 'image', href: iconsHref, rel: 'preload' },
		// Preload CSS as a resource to avoid render blocking
		{ href: '/favicon.svg', rel: 'icon', type: 'image/svg+xml' },
		{
			crossOrigin: 'use-credentials',
			href: '/site.webmanifest',
			rel: 'manifest',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ href: '/favicon.svg', rel: 'icon', type: 'image/svg+xml' },
		{ href: tailwindStyleSheetUrl, rel: 'stylesheet' },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? bbai.marketing.name : `Error | ${bbai.marketing.name}` },
		{
			content: bbai.marketing.description.meta,
			name: 'description',
		},
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const { id: userId, tenantId } =
		(await time(() => getUser(request), {
			desc: 'getUser in root',
			timings,
			type: 'getUser',
		})) ?? {}

	const user =
		userId && tenantId
			? await time(
					() =>
						db.query.userSchema.findFirst({
							where: filterById({ id: userId, tenantId }),
							with: {
								image: true,
								roles: { with: { role: true } },
								tenant: true,
							},
						}),
					{ desc: 'find user in root', timings, type: 'find user' },
				)
			: null
	if (userId && !user) {
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ redirectTo: '/', request })
	}

	const { headers: toastHeaders, toast } = await getToast(request)
	const honeyProps = honeypot.getInputProps()
	const [csrfToken, csrfCookieHeader] = await csrf.commitToken()

	const allProjects = user
		? await db.query.projectSchema.findMany({
				columns: { id: true, name: true },
				where: filterByTenant({ tenantId: user.tenantId }),
			})
		: []
	return data(
		{
			allProjects,
			csrfToken,
			ENV: getEnv(),
			honeyProps,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			// styles,
			toast,
			user,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
				csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

function Document({
	children,
	env = {},
	nonce,
	styles,
	theme = 'light',
}: {
	children: React.ReactNode
	env?: Record<string, string | undefined>
	nonce: string
	styles?: Style[]
	theme?: Theme
}) {
	return (
		<html className={`${theme} h-full`} lang="en">
			<head>
				<meta charSet="utf-8" />
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta content="width=device-width,initial-scale=1" name="viewport" />
				<meta
					content="BrainBuild is a platform that enables you to quickly generate product specs and wireframes using AI, helping you move from an idea to a prototype design in minutes. Tools for businesses, designers, and developers with features for your entire workflow."
					name="description"
				/>
				<meta content="website" property="og:type" />
				<Links />
				<style>
					{`
						:root {
							${stylesToCssVars(styles ?? [])}
						}
					`}
				</style>
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
					nonce={nonce}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const user = useOptionalUser()
	const theme = useTheme()
	const routes = useMatches()
	const routeType = getRouteType(routes)
	useToast(data.toast)

	if (routes.some((r) => r.pathname === '/page-exporter')) {
		return (
			<Document env={data.ENV} nonce={nonce} theme={theme}>
				<Outlet />
			</Document>
		)
	}
	return (
		<Document env={data.ENV} nonce={nonce} theme={theme}>
			<Layout
				allProjects={data.allProjects}
				routeType={routeType}
				theme={data.requestInfo.userPrefs.theme}
				user={user}
			>
				<Outlet />
			</Layout>
			<Toaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}

export const routeTypes = ['internal', 'marketing', 'auth', 'other'] as const

function getRouteType(
	routes: UIMatch<unknown, unknown>[],
): (typeof routeTypes)[number] {
	let match = 'other'
	for (const routeType of routeTypes) {
		const routeId = routes[1].id
		if (routeId.startsWith(`routes/_wizard`)) {
			match = 'internal'
			break
		} else if (routeId.startsWith(`routes/_auth`)) {
			match = 'marketing'
			break
		} else if (routeId.startsWith(`routes/_${routeType}+`)) {
			match = routeType
			break
		}
	}

	return match as (typeof routeTypes)[number]
}
